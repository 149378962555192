import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import backgroundImage from '../data/images/404/404-hero.jpg';
import responsiveBackgroundImage from '../data/images/404/404-hero-mobile.jpg';
import Layout from '../components/layout';
import Button from '../components/button';
import Translate from '../components/Translate';

const styles = {
    container: {
        margin: '150px 0 0 17%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '65%',
        alignItems: 'center',
        '& h1': {
            fontSize: '4em',
            textAlign: 'center',
            lineHeight: '40px'
        },
        '& h2': {
            fontSize: '1.5em',
            fontWeight: '400',
            textAlign: 'center',
            lineHeight: '30px'
        }
    },
    column2: {
        marginBottom: '15px'
    },
    '@media only screen and (max-width: 767px)': {
        container: {
            margin: '50px 0 0 5%',
            width: '90%',
            '& h1': {
                fontSize: '2em'
            },
            '& h2': {
                fontSize: '1.1em'
            }
        }
    }
};

class NotFoundPage extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Layout responsiveBackgroundUrl={responsiveBackgroundImage} backgroundUrl={backgroundImage}>
                <Helmet>
                    <title>Garmin Connect | 404</title>
                </Helmet>
                <div className={classes.container}>
                    <div className={classes.column1}>
                        <h1>
                            <span>
                                <Translate ns="prelogin_pages" content="prelogin_404_page_title" />
                            </span>
                        </h1>
                    </div>
                    <div className={classes.column2}>
                        <h2>
                            <span>
                                <Translate ns="prelogin_pages" content="prelogin_404_page_content" />
                            </span>
                        </h2>
                    </div>
                    <div className={classes.column3}>
                        <Link to="/">
                            <Button size="large">
                                <Translate ns="prelogin_pages" content="prelogin_404_page_button" />
                            </Button>
                        </Link>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(NotFoundPage);
